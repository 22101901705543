import { render, staticRenderFns } from "./scoreSort.vue?vue&type=template&id=2a969240&scoped=true&"
import script from "./scoreSort.vue?vue&type=script&lang=js&"
export * from "./scoreSort.vue?vue&type=script&lang=js&"
import style0 from "./scoreSort.vue?vue&type=style&index=0&id=2a969240&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a969240",
  null
  
)

export default component.exports