<template>
    <CardComponent
        :title="title"
        :icon="icon"
        :tip="tip"
        link="/profile/score-manage"
        :filter="true"
        width=".8rem"
        class="score-sort-card"
    >
        <section style="margin-top:25px">
            <el-radio-group
                v-model="radio1"
                size="small"
                @change="handleChange"
            >
                <el-radio-button
                    v-for="(tab, index) in tabs"
                    :key="index"
                    :label="tab.value"
                    >{{ tab.label }}</el-radio-button
                >
            </el-radio-group>
            <gb-echart
                ref="scoreSortChart"
                config="score-sort-config"
                height="100%"
                width="100%"
            />
        </section>
    </CardComponent>
</template>

<script>
import CardComponent from '../components/card'
import GbEchart from '@/components/Echart'

export default {
    name: 'ScoreSortCard',
    components: { CardComponent, GbEchart },
    data() {
        return {
            title: '积分排名TOP10',
            tip: '积分排名TOP10',
            icon: 'iconfont icon-jifenpaihang',
            tabs: [
                {
                    label: '当季积分',
                    value: 1
                },
                {
                    label: '全年积分',
                    value: 2
                }
            ],
            radio1: 1
        }
    },
    created() {
        this.initData()
    },
    methods: {
        handleChange() {
            this.initData()
        },
        async initData() {
            let api =
                this.radio1 === 1
                    ? '/learning/learning-score-current-season-ranking'
                    : '/learning/learning-score-current-year-ranking'
            let res = await this.$axios.fetch(
                'learningServer',
                api,
                {
                    number: 10
                },
                'json'
            )
            if (res.data && res.data.length > 0) {
                this.setChartData(res.data)
            }
        },
        setChartData(data) {
            let xAisData = data.map(item => item.studentName)
            let seriesData = data.map(item => item.score)

            this.$nextTick(() => {
                let echart = this.$refs.scoreSortChart
                echart.option.xAxis.data = xAisData
                echart.option.series[0].data = seriesData
                echart.refresh()
            })
        }
    }
}
</script>
<style lang="less" scoped>
.score-sort-card {
  grid-row-start: span 2;
}
section {
    position: relative;
    /deep/ .el-radio-group {
        position: absolute;
        // top: .5vh;
        z-index: 2;
        display: flex;
        justify-content: center;
        width: 100%;
        .el-radio-button__inner {
            font-size: 12px;
            // padding: 9px 20px;

            width: 100px;
            padding: 7px 0;
            text-align: center;
            color: #636a6f;
            background: transparent;
        }
        .el-radio-button__orig-radio:checked + .el-radio-button__inner {
            color: #fff;
            border-color: #69acf1;
            background-color: #69acf1;
        }
        .el-radio-button:nth-child(1) {
            .el-radio-button__inner {
                border-radius: 10px 0 0 10px;
            }
        }
        .el-radio-button:last-child {
            .el-radio-button__inner {
                border-radius: 0 10px 10px 0;
            }
        }
    }
}
</style>
